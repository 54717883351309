import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

//日志管理
//获取关联日志列表
function getAssociationLogList(data) {
    return router({
        url: baseUrl + "log/v1/getAssociationLogList",
        params: data,
        method: "GET"
    })
}
//获取集成日志列表
function getIntegrateLogList(data) {
    return router({
        url: baseUrl + "log/v1/getIntegrateLogList",
        params: data,
        method: "GET"
    })
}
//获取任务日志列表
function getTaskScheduleLogList(data) {
    return router({
        url: baseUrl + "log/v1/getTaskScheduleLogList",
        params: data,
        method: "GET"
    })
}
export {
    getAssociationLogList,
    getIntegrateLogList,
    getTaskScheduleLogList
};