<template>
  <div class="integrations_logs">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("devices.Welcome")
      }}</router-link>
      > {{ $t("integrationsLogs.logs") }} >
      <span style="color: #3461ff">{{
        $t("integrationsLogs.Integrations logs")
      }}</span>
    </div>
    <div class="table_set">
      <div class="select_filter">
        <div>
          <span class="text"
            >{{ $t("integrationsLogs.Records") }}:{{ pageParams.total }}</span
          >
        </div>
      </div>
      <el-table
        ref="tableRows"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="table-topstyle"
        @select-all="handleSelectAll"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55">
          <el-table-column type="selection" />
        </el-table-column>
        <el-table-column :label="$t('integrationsLogs.State')">
          <el-table-column prop="stateDesc" min-width="130">
            <template slot="header">
              <el-select
                v-model="pageParams.state"
                placeholder=""
                size="mini"
                clearable
                @change="getIntegrateLogListReq"
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.codeId"
                  :label="item.codeName"
                  :value="item.codeId"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('integrationsLogs.Type')">
          <el-table-column
            prop="syncMode"
            min-width="130"
            show-overflow-tooltip
          >
            <template slot="header">
              <el-select
                v-model="pageParams.syncMode"
                placeholder=""
                size="mini"
                clearable
                @change="getIntegrateLogListReq"
              >
                <el-option
                  v-for="item in syncModeList"
                  :key="item.codeId"
                  :label="item.codeName"
                  :value="item.codeId"
                />
              </el-select>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.syncModeDesc}}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('integrationsLogs.File Name')"
          sortable="custom"
          prop="fileName"
        >
          <el-table-column
            prop="fileName"
            min-width="150"
            show-overflow-tooltip
          >
            <template slot="header">
              <el-input
                clearable
                v-model="pageParams.fileName"
                size="mini"
                @input="getIntegrateLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('integrationsLogs.Start Date')"
          sortable="custom"
          prop="startTime"
        >
          <el-table-column
            prop="startTime"
            min-width="220"
            show-overflow-tooltip
          >
            <template slot="header">
              <el-date-picker
                clearable
                v-model="pageParams.startTime"
                type="daterange"
                align="center"
                size="mini"
                class="intergation_picker"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="getIntegrateLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('integrationsLogs.End Date')"
          sortable="custom"
          prop="endTime"
        >
          <el-table-column prop="endTime" min-width="220" show-overflow-tooltip>
            <template slot="header">
              <el-date-picker
                clearable
                v-model="pageParams.endTime"
                type="daterange"
                align="center"
                size="mini"
                class="intergation_picker"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="getIntegrateLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('integrationsLogs.Operator')"
          sortable="custom"
          prop="createUser"
        >
          <el-table-column prop="createUser" min-width="120">
            <template slot="header">
              <el-input
                clearable
                v-model="pageParams.createUser"
                size="mini"
                @input="getIntegrateLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('integrationsLogs.IP')" sortable="custom" prop="ip">
          <el-table-column prop="ip" min-width="80">
            <template slot="header">
              <el-input
                clearable
                v-model="pageParams.ip"
                size="mini"
                @input="getIntegrateLogListReq"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('integrationsLogs.Processed')"
          sortable="custom"
          prop="processeds"
        >
          <el-table-column prop="processeds" width="200">
            <template slot="header">
              <div style="display: flex; align-items: center">
                <el-input
                  clearable
                  v-model="pageParams.startProcessed"
                  size="mini"
                  style="margin-right: 5px"
                  @input="getIntegrateLogListReq"
                />
                <el-input
                  clearable
                  v-model="pageParams.endProcessed"
                  size="mini"
                  style="margin-left: 5px"
                  @input="getIntegrateLogListReq"
                />
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('integrationsLogs.Errors')"
          sortable="custom"
          prop="errors"
        >
          <el-table-column prop="errors" width="200">
            <template slot="header">
              <div style="display: flex; align-items: center">
                <el-input
                  clearable
                  v-model="pageParams.startError"
                  size="mini"
                  style="margin-right: 5px"
                  @input="getIntegrateLogListReq"
                />
                <el-input
                  clearable
                  v-model="pageParams.endError"
                  size="mini"
                  style="margin-left: 5px"
                  @input="getIntegrateLogListReq"
                />
              </div>
            </template>
            <template slot-scope="scope">
              {{ scope.row.errors }}
              <i
                class="el-icon-search integation_serch"
                @click="quertError(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('integrationsLogs.Message')"
          sortable="custom"
          prop="message"
        >
          <el-table-column prop="message" width="150">
            <template slot="header">
              <div style="display: flex; align-items: center">
                <el-input
                  clearable
                  v-model="pageParams.message"
                  size="mini"
                  @input="getIntegrateLogListReq"
                />
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('integrationsLogs.Download')" fixed="right" width="100">
          <el-table-column prop="operateType" width="100" align="center">
            <template slot="header" />
            <template slot-scope="scope">
              <i
                class="el-icon-download integation_download"
                style="font-size: 20px;line-height: 24px;"
                @click="download(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <!-- <div class="d-flex justify-content-between flex-wrap b_pag">
        <div class="d-flex align-items-center">
          <span class="text-nowrap">{{ $t("pagination.Display") }}</span>
          <el-select v-model="pageParams.size" @change="getIntegrateLogListReq">
            <el-option
              v-for="item in ['10', '20', '50', '100', '200', '500', '1000']"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="text-nowrap">{{ $t("pagination.Strip") }}</span>
        </div>
        <el-pagination
          background
          :page-size.sync="pageParams.size"
          :current-page.sync="pageParams.current"
          prev-text="Previous"
          next-text="Next"
          layout="prev, pager, next"
          :total="pageParams.total"
          @size-change="getIntegrateLogListReq"
          @current-change="getIntegrateLogListReq"
        />
      </div> -->
      <pagination :tableInfo="pageParams" @changePage="changePage" />
    </div>
    <!-- table -->
    <errors-log-dialog ref="errorsLogDialogRef" />
  </div>
</template>
<script>
import errorsLogDialog from '@/views/logs/integrations/dialog/errorsLog.vue'
import { commonStore } from '@/store/global-select-store'

import modelUi from "@/views/taskManagement/taskManger/utils/modelUi.js";

import { getCodeList } from "@/libs/api/taskManger.js";

import { dateFormat } from "@/views/taskManagement/taskManger/utils/dateUtil.js";

import {
  parseResp,
  parseError,
} from "@/views/taskManagement/taskManger/utils/requestUtil.js";

import { getStoreId } from "@/views/taskManagement/taskManger/utils/cacheUtil.js";
import { getIntegrateLogList } from "@/libs/api/logManger";
import pagination from '@/components/pagination'

export default {
  components: {
    errorsLogDialog,
    pagination
  },
  data() {
    return {
      commonStore,
      stateList: [],
      syncModeList: [],
      storeQueryTypeList: [
        {
          dictValue: 2,
          dictName: "This Store",
        },
        {
          dictValue: 1,
          dictName: "Next Level Stores",
        },
        {
          dictValue: 0,
          dictName: "All Descendant Stores",
        },
      ],
      leng: "",
      tableData: [],
      pageParams: {
        current: 1,
        size: 10,
        total: 0,
        state: "",
        syncMode: "",
        storeType: 2,
        storeName: "",
        fileName: "",
        storeId: "",
        createUser: "",
        message: "",
        startTime: null,
        endTime: null,
        startProcessed: "",
        endProcessed: "",
        startError: "",
        endError: "",
        orders: [],
      },
    };
  },

  created() {
    commonStore.setInjectCallBack(this.initPage);
    // this.getIntegrateLogListReq();

    // this.getStateListReq();
    // this.getSyncModeListReq();
    this.initPage();
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language;
    },
  },
  watch: {
    language(val) {
      if (val) this.initPage();
    },
  },
  methods: {
    initPage() {
      this.getIntegrateLogListReq();
      this.getStateListReq();
      this.getSyncModeListReq();
    },
    changePage() {
      this.getIntegrateLogListReq()
    },
    handleSortChange(v1) {
      const { prop } = v1;
      const { order } = v1;
      let orderStr = "";
      if (order === "descending") {
        orderStr = "desc";
      }
      if (order === "ascending") {
        orderStr = "asc";
      }

      let keyName = prop;
      if (prop === "fileName") {
        keyName = "fileName";
      }
      if (prop === "startTime") {
        keyName = "startTime";
      }
      if (prop === "endTime") {
        keyName = "endTime";
      }
      if (prop === "createUser") {
        keyName = "createUser";
      }
      if (prop === "ip") {
        keyName = "ip";
      }
      if (prop === "errors") {
        keyName = "errors";
      }
      if (prop === "processeds") {
        keyName = "processeds";
      }

      if (orderStr) {
        const orders = [];
        const orderItem = `${orderStr}:${keyName}`;
        orders.push(orderItem);
        this.pageParams.orders = orders;
      } else {
        this.pageParams.orders = [];
      }
      this.getIntegrateLogListReq();
    },

    getStateListReq() {
      getCodeList(19)
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            this.stateList = result.data;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },

    getSyncModeListReq() {
      getCodeList(20)
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            this.syncModeList = result.data;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },

    selection() {
      return this.$refs.tableRows && this.$refs.tableRows.selection.length;
    },
    handleSelectAll() {
      const { isAllSelected } = this.$refs.tableRows.store.states;
      console.log(
        "selection==",
        this.$refs.tableRows.store.states.isAllSelected
      );
      if (isAllSelected) {
        const arr = [];
        selection.forEach((it) => {
          arr.push(...it.children);
        });
        this.selectChild(arr);
      } else {
        this.$refs.tableRows.clearSelection();
      }
    },
    // 获取列表数据
    getIntegrateLogListReq() {
      this.getIntegrateLogList();
    },
    async getIntegrateLogList() {
      const {
        current,
        size,
        state,
        createUser,
        syncMode,
        storeType,
        storeName,
        startProcessed,
        endProcessed,
        startError,
        message,
        endError,
        startTime,
        endTime,
        orders,
        fileName,
      } = this.pageParams;

      let startTimeStr = "";
      if (startTime && startTime.length > 0) {
        startTimeStr = `${startTime[0]},${startTime[1]}`;
      }

      let endTimeStr = "";
      if (endTime && endTime.length > 0) {
        endTimeStr = `${endTime[0]},${endTime[1]}`;
      }

      const startProcessedStr = startProcessed || "";
      const endProcessedStr = endProcessed || "";

      const startErrorStr = startError || "";
      const endErrorStr = endError || "";

      let processed = "";
      if (startProcessedStr && endProcessedStr) {
        processed = `${startProcessedStr},${endProcessedStr}`;
      }

      let erros = "";
      if (startErrorStr && endErrorStr) {
        erros = `${startErrorStr},${endErrorStr}`;
      }

      const data = {
        state,
        syncMode,
        createTime: null,
        startTime: startTimeStr,
        endTime: endTimeStr,
        createUser,
        storeName,
        storeId: getStoreId() || 1,
        storeType,
        current,
        message,
        size,
        orders,
        processed,
        erros,
        fileName,
      };
      const res = await this.$Api.getIntegrateLogList(data);
      this.rows = [];
      this.leng = res.data.total;
      this.pageParams.total = res.data.total;
      this.tableData = res.data.records;
    },
    // 查询错误
    quertError(rows) {
      const { batchId } = rows;
      this.$refs.errorsLogDialogRef.openDialog(batchId);
    },
    // 下载文件
    download(rows) {
      if (rows.downloadUrl) {
        const el = document.createElement("a");
        el.style.display = "none";
        el.setAttribute("target", "_blank");
        el.setAttribute("download", "");
        el.href = rows.downloadUrl;
        console.log(el);
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .storelistrom {
// padding: 0 10px 10px;
// }
.integrations_logs {
  .intergation_picker {
    width: 200px;
  }
  .integation_serch {
    margin-left: 10px;
    cursor: pointer;
  }
  .integation_serch:hover {
    color: brown;
  }
  .integration_link {
    color: #000 !important;
    border-radius: 20px;
  }
  .integation_download {
    cursor: pointer;
  }
  .integation_download:hover {
    color: #00cfe8;
  }
}
.txtalein {
  padding-bottom: 20px;
  color: #000;
  font-size: 12px;
}
.handleBtn {
  display: flex;
  justify-content: space-between;
}
::v-deep .hideClass {
  visibility: hidden;
}
.icon {
  padding: 6px;
  cursor: pointer;
  .svg-icon {
    width: 23px;
    height: 23px;
  }
}
.text {
  padding: 0 15px;
  font-size: 12px;
}
.table_set {
  padding: 20px 0 0;
  background-color: white;
  border-radius: 6px;
  .select_filter {
    padding-bottom: 15px;
    .table_label_size {
      width: 160px;
      font-size: 16px;
    }
    .table_select {
      width: 214px;
    }
  }
  .icon {
    padding: 6px;
    cursor: pointer;
    .svg-icon {
      width: 23px;
      height: 23px;
    }
  }
  .b_pag {
    // padding: 0 35px;
  }
  .tb_checkbox {
    vertical-align: middle;
  }
}
</style>
