<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    width="900px"
    class="errors-log"
    style="padding: 10px"
  >
    <div slot="title">
      <span class="dialog-title">{{$t('integrationsLogs.Failure Details')}}</span>
    </div>
    <el-table
      v-if="dialogVisible"
      :data="tableData"
      style="width: 100%"
      class="table-topstyle"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <template v-if="sourcePage === 'task'">
        <el-table-column :label="$t('integrationsLogs.seq')" align="center" prop="tagIdentity" />
      </template>
      <template v-else>
        <el-table-column :label="$t('integrationsLogs.seq')" align="center" prop="seq" />
      </template>
      <el-table-column :label="$t('integrationsLogs.message')" align="center" prop="message" />
      <el-table-column :label="$t('integrationsLogs.expireTime')" align="center" prop="createTime" /> 
    </el-table>

    <!-- 分页 -->
    <pagination :tableInfo="pageParams" @changePage="changePage" />

  </el-dialog>
</template>

<script>
import pagination from '@/components/pagination'

export default {
  components: {pagination},
  data () {
    return {
      dialogVisible: false,
      batchId: "",
      message: "",
      tableData: [],
      pageParams: {
        current: 1,
        size: 10,
        total: 0,
        storeType: 2,
      },
      sourcePage:''
    };
  },
  methods: {
    changePage() {
      if(this.sourcePage === 'task'){
        this.getErrorDetailList(this.taskScheduleId)
      } else {
        this.getErrorList()
      }
    },
    openDialog (data) {
      this.dialogVisible = true;
      if(data && data.type === 'task'){
        this.sourcePage = 'task'
        this.taskScheduleId = data.id
        this.getErrorDetailList(data.id)
        return
      }
      if (data) {
        this.batchId = data;
        this.getErrorList();
      }
    },

    cancel () {
      this.dialogVisible = false;
    },
    async getErrorList () {
      console.log('this.pageParams',this.pageParams)
      let data = {
        batchId: this.batchId,
        startDate: "",
        endDate: "",
        current: this.pageParams.current,
        size: this.pageParams.size,
        orders: []
      };
      const res = await this.$Api.getErrorList(data);
      this.rows = [];
      this.leng = res.data.total;
      this.pageParams.total = res.data.total;
      this.tableData = res.data.records;
    },
    async getErrorDetailList (taskScheduleId) {
      let data = {
        taskScheduleId: taskScheduleId,
        current: this.pageParams.current,
        size: this.pageParams.size,
        orders: []
      };
      const res = await this.$Api.getErrorDetailList(data);
      this.rows = [];
      this.leng = res.data.total;
      this.pageParams.total = res.data.total;
      this.tableData = res.data.records;
    },
  },
};
</script>

<style lang="scss" scope>
.errors-log {
  .el-dialog__body {
    padding: 30px 20px !important;
  }

  .dialog-footer__led {
    display: flex;
    justify-content: center;
  }

  .button-next {
    background-color: #17b54d;
    border: 0px;
    color: #ffffff;
    width: 120px;
    height: 50px;
    font-size: 18px;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
